import React from "react";
import { Users } from "lucide-react";

interface TotalVendorsProps {
  vendors: number | null;
}

const TotalVendors: React.FC<TotalVendorsProps> = ({ vendors }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg h-full">
      <div className="flex items-center p-6 space-x-4">
        <div className="bg-amber-100 p-3 rounded-full">
          <Users className="w-8 h-8 text-blue-500" />
        </div>
        <div>
          <h3 className="text-lg font-bold text-gray-800">Total Vendors</h3>
          <p className="text-3xl font-medium text-gray-600">
            {vendors !== null ? vendors.toLocaleString() : "Loading..."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TotalVendors;