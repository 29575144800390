import React, { useState } from "react";
import { motion } from "framer-motion";
import Logo from "../../assets/VAS2Nets-Logo.png";
import NavLinks from "./NavLinks";

const Navigation: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="w-full bg-white shadow-md fixed top-0 left-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <img 
              src={Logo} 
              alt="vas2nets logo" 
              className="h-8 w-auto"
            />
          </motion.div>

          {/* Navigation Links */}
          <NavLinks isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
    </nav>
  );
};

export default Navigation;