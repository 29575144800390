import React from "react";
import HistoryTable from "./history_table/HistoryTable";

const History: React.FC = () => {
  return (
    <section>
      <header>
        <button className="bg-red-500 text-white font-bold text-xl shadow-lg rounded-md max-w-sm py-2 px-6 tracking-wide md:text-2xl my-4">
          Clients
        </button>
      </header>

      <HistoryTable />
    </section>
  );
};

export default History;
