import axios, {
  AxiosInstance,
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from "axios";

const getAuthToken = (): string | null => {
  return localStorage.getItem("token");
};

export const BaseURL: AxiosInstance = axios.create({
  baseURL: "https://vasvending.v2napi.com/",
  // baseURL: "http://localhost/vasvending-laravel/public/",
  validateStatus: (status: number): boolean => {
    return status >= 200 && status < 500;
  },
  headers: {
    "Content-Type": "application/json",
  },
});

BaseURL.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

BaseURL.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status === 401) {
      window.location.href = "/";
    }

    return response;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default BaseURL;
