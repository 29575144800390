import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Loader from "./Loader";
import Login from "./Login";
import Layout from "./Layout";
import Dashboard from "./Dashboard";
import MerchantLayout from "./MerchantLayout";
import MerchantDashboard from "./MerchantDashboard";
import Vendors from "./Vendors";
import Commision_History from "./components/vendor/history/commission_history/Commision_History";
import Commissions from "./components/merchants/commissions/Commissions";

const App: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Loader />,
    },
    {
      path: "/dashboard",
      element: <Layout />,
      children: [
        { index: true, element: <Dashboard /> },
        { path: "vendors", element: <Vendors /> },
        { path: "commission-history/:name", element: <Commision_History /> },
      ],
    },
    {
      path: "/merchants/:name",
      element: <MerchantLayout />,
      children: [
        { index: true, element: <MerchantDashboard /> },
        { path: "commissions", element: <Commissions /> },
      ],
    },
    { path: "/login", element: <Login /> },
  ]);
  return <RouterProvider router={router} />;
};

export default App;
