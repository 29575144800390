import React, { useState, useEffect } from "react";
import { X, ChevronLeft, ChevronRight, Calendar } from "lucide-react";
import BaseURL from "../../../utils/url/http";
import { CSVLink } from "react-csv";

interface TransferHistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  username: string;
}

interface TransferRecord {
  id: number;
  initial_balance: number;
  amount_deducted: number;
  current_balance: number;
  transfer_date: string;
}

interface PaginatedResponse {
  current_page: number;
  data: TransferRecord[];
  last_page: number;
  per_page: number;
  total: number;
}

const csvHeaders = [
  { label: "Date", key: "transfer_date" },
  { label: "Initial Balance", key: "initial_balance" },
  { label: "Amount Deducted", key: "amount_deducted" },
  { label: "Current Balance", key: "current_balance" },
];

const TransferHistoryModal: React.FC<TransferHistoryModalProps> = ({
  isOpen,
  onClose,
  username,
}) => {
  const [transfers, setTransfers] = useState<PaginatedResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fetchTransfers = async (page: number) => {
    setLoading(true);
    try {
      let url = `vasadmin/commission/transferHistory/${username}?page=${page}`;
      if (startDate && endDate) {
        url += `&start_date=${startDate}&end_date=${endDate}`;
      }

      const response = await BaseURL.get(url);
      if (response.data.status === "success") {
        setTransfers(response.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch transfer history:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchTransfers(currentPage);
    }
  }, [isOpen, currentPage, startDate, endDate]);

  const csvData =
    transfers?.data.map((transfer: TransferRecord) => ({
      ...transfer,
      transfer_date: new Date(transfer.transfer_date).toLocaleString(),
    })) || [];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl w-full max-w-3xl mx-4 p-6">
        <div className="flex items-center justify-between mb-6">
          <CSVLink
            headers={csvHeaders}
            data={csvData}
            filename={`transfer_history_${username}.csv`}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            Download CSV
          </CSVLink>
          <h2 className="text-2xl font-bold text-gray-800">Transfer History</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="w-6 h-6 text-gray-500" />
          </button>
        </div>

        <div className="flex gap-4 mb-6">
          <div className="flex items-center gap-2">
            <Calendar className="w-5 h-5 text-gray-500" />
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div>
          <div className="flex items-center gap-2">
            <Calendar className="w-5 h-5 text-gray-500" />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b-2 border-gray-100 bg-gray-200">
                <th className="text-left py-3 px-4 text-gray-600">Date</th>
                <th className="text-left py-3 px-4 text-gray-600">
                  Initial Balance
                </th>
                <th className="text-left py-3 px-4 text-gray-600">
                  Amount Deducted
                </th>
                <th className="text-left py-3 px-4 text-gray-600">
                  Current Balance
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={4} className="text-center py-4">
                    Loading...
                  </td>
                </tr>
              ) : transfers?.data.length === 0 ? (
                <tr>
                  <td colSpan={4} className="text-center py-4 text-gray-500">
                    No transfers found
                  </td>
                </tr>
              ) : (
                transfers?.data.map((transfer) => (
                  <tr
                    key={transfer.id}
                    className="border-b border-gray-50 hover:bg-gray-50"
                  >
                    <td className="py-3 px-4 text-gray-800">
                      {new Date(transfer.transfer_date).toLocaleString()}
                    </td>
                    <td className="py-3 px-4 text-gray-800 bg-gray-100">
                      ₦{transfer.initial_balance.toLocaleString()}
                    </td>
                    <td className="py-3 px-4 text-red-600">
                      - ₦{Math.abs(transfer.amount_deducted).toLocaleString()}
                    </td>
                    <td className="py-3 px-4 text-green-600">
                      ₦{transfer.current_balance.toLocaleString()}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {transfers && transfers.last_page > 1 && (
          <div className="mt-6 flex items-center justify-between">
            <div className="text-sm text-gray-600">
              Showing {transfers.per_page} of {transfers.total} entries
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
                className={`p-2 rounded-lg ${
                  currentPage === 1
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                <ChevronLeft className="w-5 h-5" />
              </button>
              <span className="flex items-center px-3">
                Page {currentPage} of {transfers.last_page}
              </span>
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === transfers.last_page}
                className={`p-2 rounded-lg ${
                  currentPage === transfers.last_page
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                <ChevronRight className="w-5 h-5" />
              </button>
            </div>
          </div>
        )}

        <div className="mt-6 flex justify-end">
          <button
            onClick={onClose}
            className="px-6 py-2 bg-gray-100 text-gray-700 rounded-xl hover:bg-gray-200 transition-colors font-medium"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransferHistoryModal;
