import React from 'react';
import { motion } from 'framer-motion';
import { Eye, EyeOff, Wallet } from 'lucide-react';

interface WalletBalanceCardProps {
  balance: number;
  showBalance: boolean;
  toggleBalanceVisibility: () => void;
}

const WalletBalanceCard: React.FC<WalletBalanceCardProps> = ({ balance, showBalance, toggleBalanceVisibility }) => {
  return (
    <div className="w-full p-4 flex items-start lg:justify-start justify-center">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-2xl p-6 shadow-lg w-full max-w-md border border-green-500/20"
      >
        {/* Card Header */}
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-2">
            <motion.div
              whileHover={{ scale: 1.1 }}
              className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center"
            >
              <Wallet className="text-green-600 w-5 h-5" />
            </motion.div>
            <h2 className="text-lg font-semibold text-gray-800">Wallet Balance</h2>
          </div>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={toggleBalanceVisibility}
            className="text-green-600 hover:text-green-700 focus:outline-none"
          >
            {showBalance ? (
              <EyeOff className="w-5 h-5" />
            ) : (
              <Eye className="w-5 h-5" />
            )}
          </motion.button>
        </div>

        {/* Balance Display */}
        <div className="mb-6">
          <p className="text-sm text-gray-500 mb-1">Available Balance</p>
          <motion.div 
            className="flex items-baseline space-x-2"
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
          >
            <span className="text-3xl font-bold text-gray-800">₦</span>
            <motion.span 
              key={showBalance ? 'balance' : 'asterisk'}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.2 }}
              className="text-4xl font-bold text-gray-800"
            >
              {showBalance 
                ? balance.toLocaleString('en-NG', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
                : "***,***.**"
              }
            </motion.span>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default WalletBalanceCard;
