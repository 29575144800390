import React, { useState } from "react";

interface SearchBarProps {
  onSearch: (reference: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [reference, setReference] = useState("");

  const handleSearch = () => {
    if (reference.trim()) {
      onSearch(reference);
    }
  };

  return (
    <div className="flex justify-between items-center mb-4">
      <input
        type="text"
        value={reference}
        onChange={(e) => setReference(e.target.value)}
        placeholder="Enter transaction reference"
        className="border rounded p-2 flex-grow mr-2" // Flex-grow to take available space
      />
      <button
        onClick={handleSearch}
        className="bg-red-500 text-white rounded px-4 py-2 hover:bg-red-600 transition-colors" // Red theme
      >
        Search
      </button>
    </div>
  );
};

export default SearchBar;