import React, { useState } from "react";
import { X, RefreshCw, Check, AlertCircle } from "lucide-react";

interface TransferModalProps {
  isOpen: boolean;
  onClose: () => void;
  onTransfer: (amount: number) => Promise<void>;
  commission: number;
}

const TransferModal: React.FC<TransferModalProps> = ({
  isOpen,
  onClose,
  onTransfer,
  commission,
}) => {
  const [amount, setAmount] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [transferring, setTransferring] = useState(false);
  const [success, setSuccess] = useState(false);

  if (!isOpen) return null;

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAmount(value);

    setError("");

    const numAmount = Number(value);
    if (isNaN(numAmount)) {
      setError("Please enter a valid number");
    } else if (numAmount <= 0) {
      setError("Amount must be greater than 0");
    } else if (numAmount > commission) {
      setError("Amount cannot exceed available commission");
    }
  };

  const handleTransfer = async () => {
    const numAmount = Number(amount);
    if (isNaN(numAmount) || numAmount <= 0 || numAmount > commission) {
      return;
    }

    setTransferring(true);
    try {
      await onTransfer(numAmount);
      setSuccess(true);
      setTimeout(() => {
        onClose();
        setAmount("");
        setSuccess(false);
      }, 2000);
    } catch (error) {
      setError("Transfer failed. Please try again.");
    } finally {
      setTransferring(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />

      <div className="relative bg-white rounded-2xl p-6 w-full max-w-md mx-4 transform transition-all duration-300 scale-100 animate-[fadeIn_0.3s_ease-out]">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 p-1 rounded-full hover:bg-gray-100 transition-colors"
        >
          <X className="w-5 h-5 text-gray-500" />
        </button>

        <div className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">
            Transfer Commission
          </h2>
          <p className="text-gray-600">
            Available: ₦{commission.toLocaleString()}
          </p>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Amount to Transfer
          </label>
          <input
            type="number"
            value={amount}
            onChange={handleAmountChange}
            placeholder="Enter amount"
            className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 outline-none transition-all"
          />
          {error && (
            <div className="flex items-center gap-1 mt-2 text-red-500 text-sm">
              <AlertCircle className="w-4 h-4" />
              <span>{error}</span>
            </div>
          )}
        </div>

        <button
          onClick={handleTransfer}
          disabled={!amount || transferring || !!error}
          className={`
            w-full flex items-center justify-center gap-2 px-6 py-3 rounded-xl 
            text-white font-medium transition-all transform hover:scale-[1.02]
            ${
              success
                ? "bg-green-500"
                : !amount || transferring || !!error
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-indigo-600 hover:bg-indigo-700"
            }
          `}
        >
          {success ? (
            <>
              <Check className="w-5 h-5" />
              Transfer Complete
            </>
          ) : transferring ? (
            <>
              <RefreshCw className="w-5 h-5 animate-spin" />
              Processing...
            </>
          ) : (
            "Transfer"
          )}
        </button>
      </div>
    </div>
  );
};

export default TransferModal;
