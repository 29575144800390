import React, { useEffect, useState } from "react";
import TransactionTable from "./TransactionTable";
import BaseURL from "../../utils/url/http";
import { CSVLink } from "react-csv";

interface TransactionData {
  id: number;
  user_id: number;
  reference: string;
  status: string;
  vendor: string;
  created_at: string;
  updated_at: string;
}
const csvHeaders = [
  { label: "ID", key: "id" },
  { label: "User ID", key: "user_id" },
  { label: "Reference", key: "reference" },
  { label: "Status", key: "status" },
  { label: "Vendor", key: "vendor" },
  { label: "Created At", key: "created_at" },
  { label: "Updated At", key: "updated_at" },
];

const Transactions: React.FC = () => {
  const [data, setData] = useState<TransactionData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [filterLoading, setFilterLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const fetchTransactions = async () => {
    try {
      const response = await BaseURL.get("vasadmin/fetchTransactions", {
        params: {
          start_date: startDate,
          end_date: endDate,
          status,
          page: currentPage,
        },
      });
      setData(response.data?.data?.data);
      setTotalPages(response.data?.data?.last_page);
      setItemsPerPage(response.data?.data?.per_page);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [startDate, endDate, status, currentPage]);

  const fetchTransactionByReference = async (reference: string) => {
    try {
      const response = await BaseURL.post(
        "vasadmin/fetchSingleDBTransactions",
        { reference }
      );

      if (response.data.status === "success") {
        setData([response.data.data]);
      } else {
        alert(response.data.message || "Transaction not found");
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching transaction:", error);
      alert("An error occurred while fetching the transaction.");
    }
  };

  const handleFilter = async (
    newStartDate: string | null,
    newEndDate: string | null,
    newStatus: string | null
  ) => {
    setFilterLoading(true);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setStatus(newStatus);
    setCurrentPage(1);

    await fetchTransactions();

    setFilterLoading(false);
  };

  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setStatus(null);
    setCurrentPage(1);
    fetchTransactions();
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <section>
      <header>
        <button className="text-red-500 font-bold text-xl shadow-lg rounded-md max-w-sm py-2 px-6 tracking-wide md:text-2xl my-4">
          Transactions
        </button>
      </header>
      <section className="bg-white px-4 shadow-xl py-4 rounded-md">
        <div className="flex justify-between mb-4">
          <button
            className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
            onClick={clearFilters}
          >
            Clear Filters
          </button>
          {filterLoading && <span>Loading...</span>}
        </div>
        <section className="flex justify-center my-4">
          <CSVLink
            data={data}
            headers={csvHeaders}
            filename="transactions.csv"
            className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition-colors duration-200"
          >
            Download CSV
          </CSVLink>
        </section>

        <TransactionTable
          data={data}
          loading={loading}
          onSearch={fetchTransactionByReference}
          onFilter={handleFilter}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
        />
      </section>
    </section>
  );
};

export default Transactions;
