import React, { useState } from "react";
import { BaseURL } from "../utils/url/https";
import { useNavigate } from "react-router-dom";
import Toast from "../toast/Toast";

const LoginForm: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState<"error" | "success" | "info">(
    "error"
  );

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const trimmedUsername = username.trim();
    const trimmedPassword = password.trim();

    if (!trimmedUsername || !trimmedPassword) {
      setError("Both fields are required.");
      return;
    }

    setError("");
    setLoading(true);

    const res = await BaseURL.post("auth/login", {
      username: trimmedUsername,
      password: trimmedPassword,
    });

    if (res?.status === 200) {
      localStorage.setItem("token", res?.data?.token);
      setToastType("success");
      setToastMessage("Login successful!");
      setShowToast(true);

      if (res.data?.role === "merchant") {
        localStorage.setItem("username", trimmedUsername);
        navigate(`/merchants/${trimmedUsername}`);
      } else {
        localStorage.setItem("role", res.data?.role);
        navigate("/dashboard");
      }
    } else {
      setToastType("error");
      setToastMessage(res?.data?.message || "Login failed");
      setShowToast(true);
    }

    setLoading(false);
  };

  return (
    <div>
      <Toast
        message={toastMessage}
        isVisible={showToast}
        onClose={() => setShowToast(false)}
        type={toastType}
      />

      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded shadow-md w-80 z-10"
      >
        <h2 className="text-lg font-bold mb-4">Login</h2>

        {error && <p className="text-red-500 mb-4">{error}</p>}

        <div className="mb-4">
          <label
            htmlFor="username"
            className="block text-sm font-medium text-gray-700"
          >
            Username
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            onFocus={() => setError("")}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onFocus={() => setError("")}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            required
          />
        </div>

        <button
          type="submit"
          className="w-full text-white py-2 rounded shadow-sm bg-black hover:bg-red-700"
          disabled={loading}
        >
          {loading ? "Logging in..." : "Login"}
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
