import React from "react";
import CommissionHistory from "./table/CommissionHistory";
import CommissionTransfer from "./balance_transfer/CommissionTransfer";

const Commissions: React.FC = () => {
  return (
    <section className="py-24 max-w-7xl mx-auto">
      <main>
        <CommissionTransfer />
      </main>
      <section>
        <CommissionHistory />
      </section>
    </section>
  );
};

export default Commissions;
