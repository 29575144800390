import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import BaseURL from "../../utils/url/http";

interface DetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  reference: string;
}

interface TransactionResponse {
  status: number;
  message: string;
  data: {
    reference: string;
    providerId: string;
    productName: string;
    artifact: {
      receipt: string;
      amount: string;
      recipient: string;
      network: string;
      date: string;
      utilityBundle: {
        utility: string;
        UtilityRate: string;
        utilityAmount: string;
        reward: string;
        rewardAmount: string;
        rewardRate: string;
      };
      status: {
        utilityStatus: string;
        rewardStatus: string;
      };
      reward: {
        campaign: string;
        reference: string;
        totalCost: string;
        currency: string;
        submittedAt: string;
      };
    };
    status: string;
    commissions: {
      "amount(NGN)": string;
      "commissionRate(%)": string;
      "commissionGained(NGN)": string;
      "rewardAmount(NGN)": string;
      "rewardRate(%)": string;
      "vasRate(%)": string;
      "vasAmount(NGN)": string;
    };
    balanceBefore: string;
    balanceAfter: string;
  } | null;
}

const DetailsModal: React.FC<DetailsModalProps> = ({ isOpen, onClose, reference }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<TransactionResponse | null>(null);

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      if (!isOpen) return;
      
      setLoading(true);
      setError(null);
      
      try {
        const response = await BaseURL.post('vasadmin/getUserTransaction', {
          "session_id": reference
        });
        setData(response.data);
      } catch (err) {
        setError("This transaction may either be an uncompleted transaction or invalid transaction. Please contact the technical team.");
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionDetails();
  }, [isOpen, reference]);

  const InfoRow = ({ label, value }: { label: string; value: string | number }) => (
    <div className="flex justify-between py-2 border-b border-gray-100">
      <span className="text-gray-600 font-medium">{label}</span>
      <span className="text-gray-800">{value}</span>
    </div>
  );

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      className={`fixed inset-0 flex items-center justify-center z-50 ${isOpen ? "" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
      <motion.div
        className="bg-white rounded-lg shadow-xl p-6 max-w-2xl w-full mx-4 relative z-10 max-h-[90vh] overflow-y-auto"
        initial={{ y: -50 }}
        animate={{ y: 0 }}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Transaction Details</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {loading ? (
          <div className="space-y-4">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2 mb-4"></div>
              <div className="h-4 bg-gray-200 rounded w-2/3"></div>
            </div>
          </div>
        ) : error ? (
          <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-600">
            {error}
          </div>
        ) : data?.data ? (
          <div className="space-y-6">
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold mb-3">Basic Information</h3>
              <InfoRow label="Reference" value={data.data.reference} />
              <InfoRow label="Provider ID" value={data.data.providerId} />
              <InfoRow label="Product Name" value={data.data.productName} />
              <InfoRow label="Status" value={data.data.status} />
            </div>

            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold mb-3">Transaction Details</h3>
              <InfoRow label="Receipt" value={data.data.artifact.receipt} />
              <InfoRow label="Amount" value={`₦${data.data.artifact.amount}`} />
              <InfoRow label="Recipient" value={data.data.artifact.recipient} />
              <InfoRow label="Network" value={data.data.artifact.network} />
              <InfoRow label="Date" value={data.data.artifact.date} />
            </div>

            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold mb-3">Financial Summary</h3>
              <InfoRow label="Balance Before" value={`₦${data.data.balanceBefore}`} />
              <InfoRow label="Balance After" value={`₦${data.data.balanceAfter}`} />
              <InfoRow label="Commission Rate" value={`${data.data.commissions["commissionRate(%)"]}%`} />
              <InfoRow label="Commission Gained" value={`₦${data.data.commissions["commissionGained(NGN)"]}`} />
            </div>

            {data.data.artifact.utilityBundle && (
              <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="text-lg font-semibold mb-3">Utility Bundle</h3>
                <InfoRow label="Utility Type" value={data.data.artifact.utilityBundle.utility} />
                <InfoRow label="Utility Rate" value={`₦${data.data.artifact.utilityBundle.UtilityRate}`} />
                <InfoRow label="Reward Type" value={data.data.artifact.utilityBundle.reward} />
                <InfoRow label="Reward Amount" value={`₦${data.data.artifact.utilityBundle.rewardAmount}`} />
              </div>
            )}
          </div>
        ) : (
          <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 text-yellow-600">
            No transaction data available.
          </div>
        )}

        <div className="mt-6 flex justify-end">
          <button
            onClick={onClose}
            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition-colors"
          >
            Close
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default DetailsModal;