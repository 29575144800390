import React from "react";
import WalletBalance from "./components/dashboard/wallet/WalletBalance";
import VendorWallet from "./components/vendor_wallets/VendorWallet";
import Commission from "./components/commission/Commission";
import Transactions from "./components/dashboard/transactions/Transactions";

const Dashboard = () => {
  const role = localStorage.getItem("role");
  return (
    <section className="container mx-auto px-4 py-24 ">
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 mb-8 max-w-7xl">
        <div className="h-full">
          <WalletBalance />
        </div>
        {role === "super-admin" && (
          <div className="h-full pt-4 ">
            <VendorWallet />
          </div>
        )}

        {role === "super-admin" && (
          <div className="h-full pt-4 ">
            <Commission />
          </div>
        )}
      </div>
      <div className="w-full">
        <Transactions />
      </div>
    </section>
  );
};

export default Dashboard;
