import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import BaseURL from "../../../../utils/url/http";
import { CSVLink } from "react-csv";

interface CommissionRecord {
  id: number;
  user_id: number;
  transaction_id: number;
  amount: string;
  commission_amount: number;
  commission_rate: string;
  reference: string;
  network: string;
  vendor: string;
  msisdn: string;
  processed_at: string;
  created_at: string;
  updated_at: string;
}

interface ApiResponse {
  status: string;
  data: {
    current_page: number;
    data: CommissionRecord[];
    total: number;
    per_page: number;
  };
}

interface FilterParams {
  start_date: string;
  end_date: string;
  reference: string;
  vendor: string;
}

const csvHeaders = [
  { label: "ID", key: "id" },
  { label: "Vendor", key: "vendor" },
  { label: "Reference", key: "reference" },
  { label: "Network", key: "network" },
  { label: "Amount", key: "amount" },
  { label: "Commission Rate", key: "commission_rate" },
  { label: "Commission", key: "commission_amount" },
  { label: "Phone Number", key: "msisdn" },
  { label: "Date", key: "created_at" },
];

const CommissionHistoryTable: React.FC = () => {
  const [data, setData] = useState<CommissionRecord[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<FilterParams>({
    start_date: "",
    end_date: "",
    reference: "",
    vendor: "",
  });

  const columns: TableColumn<CommissionRecord>[] = [
    {
      name: "ID",
      selector: (row: CommissionRecord, index?: number) => {
        const position = (currentPage - 1) * perPage + (index ?? 0) + 1;
        return position;
      },
      sortable: true,
      width: "70px",
    },
    {
      name: "Vendor",
      selector: (row: CommissionRecord) => row.vendor,
      sortable: true,
      grow: 2,
    },
    {
      name: "Reference",
      selector: (row: CommissionRecord) => row.reference,
      sortable: true,
      grow: 2,
      width: "220px",
    },
    {
      name: "Network",
      selector: (row: CommissionRecord) => row.network,
      sortable: true,
      width: "100px",
    },
    {
      name: "Amount",
      selector: (row: CommissionRecord) => row.amount,
      sortable: true,
      right: true,
      cell: (row: CommissionRecord) => (
        <span className="font-medium">
          ₦
          {parseFloat(row.amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      name: "Commission Rate",
      selector: (row: CommissionRecord) => row.commission_rate,
      sortable: true,
      right: true,
      cell: (row: CommissionRecord) => (
        <span className="text-green-600">{row.commission_rate}%</span>
      ),
      width: "200px",
    },
    {
      name: "Commission",
      selector: (row: CommissionRecord) => row.commission_amount,
      sortable: true,
      right: true,
      cell: (row: CommissionRecord) => (
        <span className="font-medium">
          ₦
          {row.commission_amount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })}
        </span>
      ),
      width: "200px",
    },
    {
      name: "Phone Number",
      selector: (row: CommissionRecord) => row.msisdn,
      sortable: true,
      width: "130px",
    },
    {
      name: "Date",
      selector: (row: CommissionRecord) => row.created_at,
      sortable: true,
      cell: (row: CommissionRecord) => (
        <span>
          {new Date(row.created_at).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </span>
      ),
      width: "180px",
    },
  ];

  const fetchCommissionHistory = async (page: number) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        page: page.toString(),
        ...(filters.start_date && { start_date: filters.start_date }),
        ...(filters.end_date && { end_date: filters.end_date }),
        ...(filters.reference && { reference: filters.reference }),
        ...(filters.vendor && { vendor: filters.vendor }),
      });

      const response = await BaseURL.get<ApiResponse>(
        `vasadmin/commission_history?${queryParams.toString()}`
      );

      if (response.data.status === "success") {
        setData(response.data.data.data);
        setTotalRows(response.data.data.total);
        setCurrentPage(response.data.data.current_page);
        setPerPage(response.data.data.per_page);
      }
    } catch (error) {
      console.error("Error fetching commission history:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    fetchCommissionHistory(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    fetchCommissionHistory(page);
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFilterSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    fetchCommissionHistory(1);
  };

  useEffect(() => {
    fetchCommissionHistory(1);
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="p-4">
        <form
          onSubmit={handleFilterSubmit}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-6"
        >
          <div className="flex flex-col">
            <label
              htmlFor="start_date"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              Start Date
            </label>
            <input
              type="date"
              id="start_date"
              name="start_date"
              value={filters.start_date}
              onChange={handleFilterChange}
              className="border border-gray-300 rounded-md p-2 text-sm focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="end_date"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              End Date
            </label>
            <input
              type="date"
              id="end_date"
              name="end_date"
              value={filters.end_date}
              onChange={handleFilterChange}
              className="border border-gray-300 rounded-md p-2 text-sm focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="reference"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              Reference
            </label>
            <input
              type="text"
              id="reference"
              name="reference"
              value={filters.reference}
              onChange={handleFilterChange}
              placeholder="Search by reference..."
              className="border border-gray-300 rounded-md p-2 text-sm focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="vendor"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              Vendor
            </label>
            <input
              type="text"
              id="vendor"
              name="vendor"
              value={filters.vendor}
              onChange={handleFilterChange}
              placeholder="Search by vendor..."
              className="border border-gray-300 rounded-md p-2 text-sm focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div className="flex items-end">
            <button
              type="submit"
              className="w-full bg-blue-600 text-white rounded-md py-2 px-4 hover:bg-blue-700 transition-colors duration-200 text-sm font-medium"
            >
              Apply Filters
            </button>
          </div>
        </form>
        <div className="py-10">
          <CSVLink
            data={data}
            headers={csvHeaders}
            filename={`commission-history-${new Date().toISOString()}.csv`}
            className="bg-green-600 text-white rounded-md py-2 px-4 hover:bg-green-700 transition-colors duration-200 text-sm font-medium"
          >
            Export to CSV
          </CSVLink>
        </div>

        <DataTable
          columns={columns}
          data={data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          striped
          highlightOnHover
          responsive
          customStyles={{
            headRow: {
              style: {
                backgroundColor: "#f8fafc",
                borderBottom: "2px solid #e2e8f0",
              },
            },
            headCells: {
              style: {
                fontWeight: "600",
                color: "#1e293b",
                padding: "1rem",
              },
            },
            cells: {
              style: {
                padding: "1rem",
              },
            },
          }}
          paginationComponentOptions={{
            rowsPerPageText: "Rows per page:",
            rangeSeparatorText: "of",
            noRowsPerPage: false,
            selectAllRowsItem: false,
          }}
        />
      </div>
    </div>
  );
};

export default CommissionHistoryTable;
