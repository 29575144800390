import React from "react";
import Transactions from "./components/merchants/transactions/Transactions";
import Info from "./components/merchants/dashboard/Info";
import TransactionInfo from "./components/merchants/dashboard/transactionInfo.tsx/TransactionInfo";

const MerchantDashboard = () => {
  return (
    <section className="py-24 px-10">
      <section>
        <Info />
        <TransactionInfo />
      </section>
      <Transactions />
    </section>
  );
};

export default MerchantDashboard;
