import React from "react";
import Info from "./info/Info";
import CommissionHistoryTable from "./table/CommissionHistoryTable";

const Commision_History: React.FC = () => {
  return (
    <section className="py-24 max-w-7xl mx-auto">
      <main>
        <Info />
      </main>
      <section>
        <CommissionHistoryTable />
      </section>
    </section>
  );
};

export default Commision_History;
