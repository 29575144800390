import React, { useEffect, useState } from "react";
import BaseURL from "../../../utils/url/http";
import TotalTransactions from "../../../vendor/total_transactions/TotalTransactions";
import TotalAmount from "../../../vendor/total_amount/TotalAmount";
import Success from "../../../vendor/status/Success";
import Unsuccess from "../../../vendor/status/Unsuccess";

const TransactionInfo: React.FC = () => {
  const [totalAmount, setTotalAmount] = useState<number | null>(null);
  const [totalTran, setTotalTran] = useState<number | null>(null);
  const [succTran, setSuccTran] = useState<number | null>(null);
  const [unsuccTran, setUnsuccTran] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const username = localStorage.getItem("username");

  const fetchVendorInfo = async () => {
    try {
      const response = await BaseURL(`vasadmin/transactions/${username}`);
      setTotalTran(response.data.total);
      setTotalAmount(response.data.totalAmount);
      setSuccTran(response.data.successful);
      setUnsuccTran(response.data.unsuccessful);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVendorInfo();
  }, []);

  return (
    <section className="container max-w-6xl mx-auto">
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8 mx-auto">
          <TotalTransactions totalTran={totalTran} />
          <TotalAmount totalAmount={totalAmount} />
          {/* <Success succTran={succTran} /> */}
          {/* <Unsuccess unsuccTran={unsuccTran} /> */}
        </div>
      )}
    </section>
  );
};

export default TransactionInfo;
