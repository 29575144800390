import React, { useEffect, useState } from "react";
import TransactionTable from "./TransactionTable";
import BaseURL from "../../utils/url/http";

interface TransactionData {
  id: number;
  user_id: number;
  reference: string;
  status: string;
  vendor: string;
  created_at: string;
  updated_at: string;
}

interface PaginationData {
  current_page: number;
  total: number;
  per_page: number;
}

const TransactionFilters: React.FC<{
  onFilterApply: (filters: any) => void;
  onReset: () => void;
}> = ({ onFilterApply, onReset }) => {
  const [reference, setReference] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleApplyFilters = () => {
    onFilterApply({
      reference,
      startDate,
      endDate,
    });
  };

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    onReset();
  };

  return (
    <div className="mb-6 p-4 bg-gray-50 rounded-lg">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Start Date
          </label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="w-full p-2 border rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            End Date
          </label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="w-full p-2 border rounded-md"
          />
        </div>
        <div className="flex items-end space-x-2">
          <button
            onClick={handleApplyFilters}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Apply Filters
          </button>
          <button
            onClick={handleReset}
            className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

const Transactions: React.FC = () => {
  const [data, setData] = useState<TransactionData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [paginationData, setPaginationData] = useState<PaginationData>({
    current_page: 1,
    total: 0,
    per_page: 10,
  });
  const name = localStorage.getItem("username");

  const fetchTransactions = async (page = 1, filters = {}) => {
    try {
      setLoading(true);
      const response = await BaseURL.get(`vasadmin/transactions/${name}`, {
        params: {
          ...filters,
          page,
        },
      });
      setData(response.data?.data.data);
      setPaginationData({
        current_page: response.data?.data.current_page,
        total: response.data?.data.total,
        per_page: response.data?.data.per_page,
      });
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const handleFilterApply = (filters: any) => {
    fetchTransactions(1, filters);
  };

  const handleReset = () => {
    fetchTransactions();
  };

  const handlePageChange = (page: number) => {
    fetchTransactions(page);
  };

  const fetchTransactionByReference = async (reference: string) => {
    const response = await BaseURL.post("vasadmin/fetchSingleDBTransactions", {
      reference,
    });

    if (response.data.status === "success") {
      setData([response.data.data]);
      setPaginationData({
        current_page: 1,
        total: 1,
        per_page: 1,
      });
    } else {
      setData([]);
      setPaginationData({
        current_page: 1,
        total: 0,
        per_page: 10,
      });
    }
  };

  return (
    <section>
      <header>
        <button className="text-red-500 font-bold text-xl shadow-lg rounded-md max-w-sm py-2 px-6 tracking-wide md:text-2xl my-4">
          Transactions
        </button>
      </header>
      <section className="bg-white px-4 shadow-xl py-4 rounded-md">
        <TransactionFilters
          onFilterApply={handleFilterApply}
          onReset={handleReset}
        />
        <TransactionTable
          data={data}
          loading={loading}
          onSearch={fetchTransactionByReference}
          paginationData={paginationData}
          onPageChange={handlePageChange}
        />
      </section>
    </section>
  );
};

export default Transactions;
