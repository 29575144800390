import React from "react";
import { CreditCard } from "lucide-react";

const TotalTransactions: React.FC<{ totalTran: number | null }> = ({
  totalTran,
}) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden h-full">
      <div className="flex items-center p-6 space-x-6">
        <div className="bg-purple-100 rounded-full p-4">
          <CreditCard className="h-10 w-10 text-purple-500" />
        </div>
        <div>
          <h3 className="text-lg font-bold text-gray-800">Total Transactions</h3>
          <p className="text-3xl font-medium text-gray-600">
            {totalTran !== null ? totalTran.toLocaleString() : "N/A"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TotalTransactions;