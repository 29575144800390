import React from "react";
import { X } from "lucide-react";
import { motion } from "framer-motion";

interface Vendor {
  id: number;
  name: string;
  username: string | null;
  role: string;
  wallet_balance: number;
}

interface FundWalletModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  formData: {
    vendorName: string;
    amount: string;
  };
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  vendors: Vendor[];
  handleVendorSelect: (vendorName: string) => void;
  loading: boolean;
}

const FundWalletModal: React.FC<FundWalletModalProps> = ({
  isOpen,
  setIsOpen,
  formData,
  handleInputChange,
  handleSubmit,
  vendors,
  handleVendorSelect,
  loading,
}) => {
  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center"
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        onClick={() => !loading && setIsOpen(false)}
      />

      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        className="relative bg-white rounded-lg w-full max-w-md mx-4 p-6 shadow-xl transform transition-all"
      >
        <button
          onClick={() => !loading && setIsOpen(false)}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 disabled:opacity-50"
          disabled={loading}
        >
          <X className="w-5 h-5" />
        </button>

        <div className="mb-5">
          <h3 className="text-lg font-semibold text-gray-900">
            Fund Vendor Wallet
          </h3>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="vendorSelect"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Select Vendor
            </label>
            <select
              id="vendorSelect"
              value={formData.vendorName}
              onChange={(e) => handleVendorSelect(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-gray-900 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm disabled:opacity-50"
              disabled={loading}
            >
              <option value="">Select a vendor</option>
              {vendors.length > 0 ? (
                vendors.map((vendor) => (
                  <option key={vendor.id} value={vendor.name}>
                    {vendor.name}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No vendors available
                </option>
              )}
            </select>
          </div>

          <div>
            <label
              htmlFor="amount"
              className="block text-sm font-medium text-gray-700"
            >
              Amount
            </label>
            <input
              type="number"
              id="amount"
              value={formData.amount}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50"
              placeholder="Enter amount"
              min="0"
              step="0.01"
              required
              disabled={loading}
            />
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 flex items-center gap-2"
              disabled={loading}
            >
              {loading ? (
                <>
                  <motion.div
                    animate={{ rotate: 360 }}
                    transition={{
                      duration: 1,
                      repeat: Infinity,
                      ease: "linear",
                    }}
                    className="w-4 h-4 border-2 border-white border-t-transparent rounded-full"
                  />
                  Processing...
                </>
              ) : (
                "Fund Wallet"
              )}
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default FundWalletModal;
