import axios, { AxiosInstance } from "axios";

export const BaseURL: AxiosInstance = axios.create({
  baseURL: "https://vasvending.v2napi.com/",
  // baseURL: "http://localhost/vasvending-laravel/public/",
  validateStatus: (status: number): boolean => {
    return status >= 200 && status < 500;
  },
  headers: {
    "Content-Type": "application/json",
  },
});
