import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import BaseURL from "../../../utils/url/http";
import { CSVLink } from "react-csv";

interface Vendor {
  id: number;
  name: string;
  username: string | null;
  role: string;
  wallet_balance: number;
  commission_rate: number;
  commission: number;
}

interface PaginationInfo {
  current_page: number;
  total: number;
  per_page: number;
}

interface ApiResponse {
  status: string;
  data: {
    data: Vendor[];
    current_page: number;
    total: number;
    per_page: number;
  };
  total_commission: number;
  total_vendors: number;
}

const csvHeaders = [
  { label: "ID", key: "id" },
  { label: "Name", key: "name" },
  { label: "Wallet Balance", key: "wallet_balance" },
  { label: "Commission Rate", key: "commission_rate" },
  { label: "Commission", key: "commission" },
];

const HistoryTable: React.FC = () => {
  const [data, setData] = useState<Vendor[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const columns: TableColumn<Vendor>[] = [
    {
      name: "ID",
      selector: (row: Vendor, index?: number) => {
        const position = (currentPage - 1) * perPage + (index ?? 0) + 1;
        return position;
      },
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row: Vendor) => row.name,
      sortable: true,
      grow: 2,
    },
    {
      name: "Wallet Balance",
      selector: (row: Vendor) => row.wallet_balance,
      sortable: true,
      right: true,
      cell: (row: Vendor) => (
        <span className="font-medium">
          ₦{row.wallet_balance.toLocaleString()}
        </span>
      ),
    },
    {
      name: "Commission Rate",
      selector: (row: Vendor) => row.commission_rate,
      sortable: true,
      right: true,
      cell: (row: Vendor) => (
        <span className="text-green-600">{row.commission_rate}%</span>
      ),
    },
    {
      name: "Commission",
      selector: (row: Vendor) => row.commission,
      sortable: true,
      right: true,
      cell: (row: Vendor) => (
        <span className="font-medium">₦{row.commission.toLocaleString()}</span>
      ),
    },
    {
      name: "",
      cell: (row: Vendor) => (
        <button
          onClick={() =>
            (window.location.href = `/dashboard/commission-history/${row.name}`)
          }
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm transition-colors duration-200"
        >
          Commission History
        </button>
      ),
      sortable: false,
      width: "200px",
    },
  ];

  const fetchVendors = async (page: number) => {
    setLoading(true);
    try {
      const response = await BaseURL.get<ApiResponse>(
        `vasadmin/vendors?page=${page}`
      );
      if (response.data.status === "success") {
        setData(response.data.data.data);
        setTotalRows(response.data.data.total);
        setCurrentPage(response.data.data.current_page);
        setPerPage(response.data.data.per_page);
      }
    } catch (error) {
      console.error("Error fetching vendors:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    fetchVendors(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    fetchVendors(page);
  };

  useEffect(() => {
    fetchVendors(1);
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="p-4 flex flex-col lg:flex-row lg:justify-end">
        <CSVLink
          data={data}
          headers={csvHeaders}
          filename="vendors.csv"
          className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 text-sm transition-colors duration-200 "
        >
          Download CSV
        </CSVLink>
      </div>

      <div className="p-4">
        <DataTable
          columns={columns}
          data={data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          striped
          highlightOnHover
          responsive
          customStyles={{
            headRow: {
              style: {
                backgroundColor: "#f8fafc",
                borderBottom: "2px solid #e2e8f0",
              },
            },
            headCells: {
              style: {
                fontWeight: "600",
                color: "#1e293b",
                padding: "1rem",
              },
            },
            cells: {
              style: {
                padding: "1rem",
              },
            },
          }}
          paginationComponentOptions={{
            rowsPerPageText: "Rows per page:",
            rangeSeparatorText: "of",
            noRowsPerPage: false,
            selectAllRowsItem: false,
          }}
        />
      </div>
    </div>
  );
};

export default HistoryTable;
