import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Navigation from "./components/navigation/Navigation";

const Layout: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <section>
      <Navigation />
      <Outlet />
    </section>
  );
};

export default Layout;
