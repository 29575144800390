import React from "react";
import Header from "./components/login/Header";
import LoginForm from "./components/login/LoginForm";
import { motion } from "framer-motion";

const Login: React.FC = () => {
  return (
    <motion.section 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="h-screen flex flex-col"
    >
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          duration: 0.5,
          ease: "easeOut"
        }}
      >
        <Header />
      </motion.div>

      <div className="flex-grow flex items-center justify-center bg-gradient-to-r from-red-500 to-red-900 relative overflow-hidden">
        {/* Background animated shapes */}
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 0.1, scale: 1 }}
          transition={{
            duration: 1,
            ease: "easeOut",
            delay: 0.2
          }}
          className="absolute w-[500px] h-[500px] bg-white rounded-full -top-1/4 -right-1/4"
        />
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 0.1, scale: 1 }}
          transition={{
            duration: 1,
            ease: "easeOut",
            delay: 0.3
          }}
          className="absolute w-[300px] h-[300px] bg-white rounded-full -bottom-1/4 -left-1/4"
        />

        <motion.div
          initial={{ 
            y: 50,
            opacity: 0,
            scale: 0.9
          }}
          animate={{ 
            y: 0,
            opacity: 1,
            scale: 1
          }}
          transition={{
            duration: 0.5,
            ease: [0.645, 0.045, 0.355, 1],
            delay: 0.4
          }}
          className="relative z-10"
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.5,
              delay: 0.6
            }}
          >
            <LoginForm />
          </motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Login;