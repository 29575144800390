// Commission.tsx
import React, { useState, FormEvent, ChangeEvent, useEffect } from "react";
import { Percent } from "lucide-react";
import { BaseURL } from "../utils/url/http";
import Toast from "../toast/Toast";
import CommissionModal from "./CommissionModal";

export interface Vendor {
  id: number;
  name: string;
  username: string | null;
  role: string;
  wallet_balance: number;
  commission_rate: number;
  commission: number;
}

const Commission: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [formData, setFormData] = useState({
    vendorName: "",
    percentage: "",
  });
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState<"error" | "success" | "info">(
    "error"
  );

  useEffect(() => {
    BaseURL.get("/vasadmin/vendors")
      .then((response) => {
        const vendorData = response.data.data.data;
        setVendors(vendorData);
      })
      .catch(() => {
        setToastMessage("Failed to fetch vendors");
        setShowToast(true);
      });
  }, []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    if (id === "percentage" && value !== "") {
      const num = parseFloat(value);
      if (isNaN(num) || num < 0 || num > 100) return;
    }
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleVendorSelect = (vendorName: string) => {
    setFormData((prev) => ({ ...prev, vendorName }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formData.vendorName || !formData.percentage) {
      setToastMessage("Please fill all fields");
      setShowToast(true);
      return;
    }

    setLoading(true);
    try {
      await BaseURL.put("/vasadmin/commission/update", {
        vendor: formData.vendorName,
        percentage: parseFloat(formData.percentage),
      });
      setToastMessage("Commission updated successfully");
      setToastType("success");
      setShowToast(true);
      setIsOpen(false);
      setFormData({ vendorName: "", percentage: "" });

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch {
      setToastMessage("Failed to update commission");
      setToastType("error");
      setShowToast(true);
    }
    setLoading(false);
  };

  return (
    <>
      <Toast
        message={toastMessage}
        isVisible={showToast}
        onClose={() => setShowToast(false)}
        type={toastType}
      />

      <div className="mx-auto w-full">
        <div
          onClick={() => setIsOpen(true)}
          className="h-52 bg-white rounded-lg shadow-lg p-6 cursor-pointer hover:shadow-xl transition-shadow duration-300 border border-gray-200"
        >
          <div className="flex flex-col h-full">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-3">
                <div className="bg-purple-100 p-3 rounded-lg">
                  <Percent className="w-6 h-6 text-purplce-600" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900">
                  Vendor Commission
                </h3>
              </div>
              <Percent className="w-6 h-6 text-purple-600" />
            </div>
            <p className="text-sm text-gray-500">
              Click to set commission percentage
            </p>
          </div>
        </div>
      </div>

      <CommissionModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        formData={formData}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        vendors={vendors}
        handleVendorSelect={handleVendorSelect}
        loading={loading}
      />
    </>
  );
};

export default Commission;
