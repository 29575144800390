import React, { useEffect, useState } from "react";
import BaseURL from "../../../utils/url/http";
import {
  ArrowRight,
  Wallet,
  CoinsIcon,
  RefreshCw,
  History,
} from "lucide-react";
import TransferModal from "./TransferModal";
import TransferHistoryModal from "./TransferHistoryModal";

interface VendorData {
  id: number;
  name: string;
  username: string | null;
  role: string;
  wallet_balance: number;
  commission_rate: number;
  commission: number;
}

const CommissionTransfer: React.FC = () => {
  const name = localStorage.getItem("username");
  const [vendorInfo, setVendorInfo] = useState<VendorData | null>(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

  useEffect(() => {
    fetchVendorInfo();
  }, [name]);

  const fetchVendorInfo = async () => {
    try {
      const res = await BaseURL.get(`vasadmin/vendors/${name}`);
      if (res.data.status === "success") {
        setVendorInfo(res.data.data);
      } else {
        console.error("Failed to fetch vendor info:", res.data.message);
      }
    } catch (error) {
      console.error("Error fetching vendor info:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTransfer = async (amount: number) => {
    try {
      const res = await BaseURL.post(
        `vasadmin/commission/transferCommission/${name}`,
        {
          amount,
        }
      );
      if (res.data.status === "success") {
        await fetchVendorInfo();
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (error) {
      console.error("Transfer failed:", error);
      return Promise.reject();
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <RefreshCw className="w-8 h-8 animate-spin text-gray-600" />
      </div>
    );
  }

  if (!vendorInfo) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-lg text-red-500">
          Failed to load vendor information
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 md:p-6 lg:p-8 mx-auto max-w-6xl">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Commission Card */}
        <div className="bg-white rounded-2xl p-6 shadow-lg border-2 border-indigo-100 hover:border-indigo-200 transition-all">
          <div className="flex items-center justify-between mb-6">
            <div className="bg-indigo-100 p-3 rounded-full">
              <CoinsIcon className="w-6 h-6 text-indigo-600" />
            </div>
            <span className="text-sm font-medium text-indigo-600 bg-indigo-50 px-3 py-1 rounded-full">
              Commission
            </span>
          </div>
          <h2 className="text-2xl font-bold text-gray-800 mb-2">
            Available Commission
          </h2>
          <p className="text-4xl font-bold text-indigo-600">
            ₦{vendorInfo.commission.toLocaleString()}
          </p>
        </div>

        {/* Wallet Card */}
        <div className="bg-white rounded-2xl p-6 shadow-lg border-2 border-purple-100 hover:border-purple-200 transition-all">
          <div className="flex items-center justify-between mb-6">
            <div className="bg-purple-100 p-3 rounded-full">
              <Wallet className="w-6 h-6 text-purple-600" />
            </div>
            <span className="text-sm font-medium text-purple-600 bg-purple-50 px-3 py-1 rounded-full">
              Wallet
            </span>
          </div>
          <h2 className="text-2xl font-bold text-gray-800 mb-2">
            Wallet Balance
          </h2>
          <p className="text-4xl font-bold text-purple-600">
            ₦{vendorInfo.wallet_balance.toLocaleString()}
          </p>
        </div>
      </div>

      {/* Transfer and History Buttons */}
      <div className="mt-8 flex justify-center gap-4">
        <button
          onClick={() => setIsModalOpen(true)}
          disabled={!vendorInfo.commission}
          className={`
            flex items-center gap-3 px-6 py-3 rounded-xl text-white font-medium
            transition-all transform hover:scale-105
            ${
              vendorInfo.commission
                ? "bg-indigo-600 hover:bg-indigo-700"
                : "bg-gray-400 cursor-not-allowed"
            }
          `}
        >
          <ArrowRight className="w-5 h-5" />
          Transfer to Wallet
        </button>

        <button
          onClick={() => setIsHistoryModalOpen(true)}
          className="flex items-center gap-3 px-6 py-3 rounded-xl text-white font-medium
            transition-all transform hover:scale-105 bg-gray-600 hover:bg-gray-700"
        >
          <History className="w-5 h-5" />
          Transfer History
        </button>
      </div>

      {/* Transfer Modal */}
      <TransferModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onTransfer={handleTransfer}
        commission={vendorInfo.commission}
      />

      {/* Transfer History Modal */}
      <TransferHistoryModal
        username={name || ""}
        isOpen={isHistoryModalOpen}
        onClose={() => setIsHistoryModalOpen(false)}
      />
    </div>
  );
};

export default CommissionTransfer;
