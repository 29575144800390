import React from "react";
import { DollarSign } from "lucide-react";

interface TotalCommissionProps {
  commission: number | null;
}

const TotalCommission: React.FC<TotalCommissionProps> = ({ commission }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg h-full">
      <div className="flex items-center p-6 space-x-4">
        <div className="bg-green-100 p-3 rounded-full">
          <DollarSign className="w-8 h-8 text-green-500" />
        </div>
        <div>
          <h3 className="text-lg font-bold text-gray-800">Total Commission</h3>
          <p className="text-3xl font-medium text-gray-600">
            {commission !== null
              ? `₦${commission.toLocaleString()}`
              : "Loading..."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TotalCommission;
