import React, { useState, FormEvent, ChangeEvent, useEffect } from "react";
import { Wallet, Plus } from "lucide-react";
import { motion } from "framer-motion";
import FundWalletModal from "./FundWalletModal";
import { BaseURL } from "../utils/url/http";
import Toast from "../toast/Toast";

interface Vendor {
  id: number;
  name: string;
  username: string | null;
  role: string;
  wallet_balance: number;
}

interface WalletFormData {
  vendorName: string;
  amount: string;
}

const VendorWallet: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [formData, setFormData] = useState<WalletFormData>({
    vendorName: "",
    amount: "",
  });
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState<"error" | "success" | "info">(
    "error"
  );

  useEffect(() => {
    BaseURL.get("/vasadmin/vendors")
      .then((response) => {
        const vendorData = response.data.data.data;
        setVendors(vendorData);
      })
      .catch(() => {
        setToastMessage("Failed to fetch vendors");
        setShowToast(true);
      });
  }, []);

  const showToastMessage = (
    message: string,
    type: "error" | "success" | "info"
  ) => {
    setToastMessage(message);
    setToastType(type);
    setShowToast(true);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { id, value } = e.target;

    if (id === "amount") {
      const regex = /^\d*\.?\d*$/;
      if (value === "" || regex.test(value)) {
        setFormData((prev) => ({
          ...prev,
          [id]: value,
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [id]: value,
      }));
    }
  };

  const handleVendorSelect = (vendorName: string) => {
    setFormData((prev) => ({
      ...prev,
      vendorName,
    }));
  };

  const validateForm = (): boolean => {
    if (!formData.vendorName) {
      showToastMessage("Please select a vendor", "error");
      return false;
    }

    const amount = Number(formData.amount);
    if (!amount || amount <= 0) {
      showToastMessage("Please enter a valid amount greater than 0", "error");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);
    try {
      await BaseURL.post("/vasadmin/wallet", {
        vendor: formData.vendorName,
        amount: parseFloat(formData.amount),
      });

      showToastMessage("Wallet funded successfully", "success");
      setIsOpen(false);
      setFormData({ vendorName: "", amount: "" });

      const response = await BaseURL.get("/vasadmin/vendors");
      setVendors(response.data.data);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      showToastMessage("Failed to fund wallet. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Toast
        message={toastMessage}
        isVisible={showToast}
        onClose={() => setShowToast(false)}
        type={toastType}
      />

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mx-auto w-full"
      >
        <div
          onClick={() => setIsOpen(true)}
          className="h-52 bg-white rounded-lg shadow-lg p-6 cursor-pointer hover:shadow-xl transition-shadow duration-300 border border-gray-200"
        >
          <div className="flex flex-col h-full">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-3">
                <div className="bg-blue-100 p-3 rounded-lg">
                  <Wallet className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900">
                  Vendor Wallet
                </h3>
              </div>
              <Plus className="w-6 h-6 text-blue-600" />
            </div>
            <p className="text-sm text-gray-500">Click to fund wallet</p>
          </div>
        </div>
      </motion.div>

      <FundWalletModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        formData={formData}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        vendors={vendors}
        handleVendorSelect={handleVendorSelect}
        loading={loading}
      />
    </>
  );
};

export default VendorWallet;
