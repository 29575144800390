import React, { useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import StatusModal from "./StatusModal";
import DetailsModal from "./DetailsModal";
import SearchBar from "./SearchBar";
import { CSVLink } from "react-csv";

interface TransactionData {
  id: number;
  user_id: number;
  reference: string;
  amount?: string | null;
  msisdn?: string | null;
  network?: string | null;
  status: string;
  vendor: string;
  created_at: string;
  updated_at: string;
  data?: {
    message?: string;
    receipt?: string;
    amount?: string | null;
    recipient?: string | null;
    network?: string | null;
    date?: string;
    utilityBundle?: {
      utility?: string;
    };
    reference?: string;
  };
}

interface PaginationData {
  current_page: number;
  total: number;
  per_page: number;
}

interface TransactionTableProps {
  data: TransactionData[];
  loading: boolean;
  onSearch: (reference: string) => void;
  paginationData: PaginationData;
  onPageChange: (page: number) => void;
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

const prepareCSVData = (data: TransactionData[]) => {
  return data.map((transaction: TransactionData) => ({
    Reference: transaction.reference,
    Recipient: transaction.msisdn ?? "N/A",
    Network: transaction.network ?? "N/A",
    Amount: transaction.amount ?? "N/A",
    Date: formatDate(transaction.created_at),
    Status: transaction.status,
  }));
};

const getStatusStyle = (status: string) => {
  return status === "completed" ? "text-green-500" : "text-red-500";
};

const TransactionTable: React.FC<TransactionTableProps> = ({
  data,
  loading,
  onSearch,
  paginationData,
  onPageChange,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [modalStatus, setModalStatus] = useState<string | null>(null);
  const [selectedReference, setSelectedReference] = useState<string>("");

  const openModal = (status?: string) => {
    setModalStatus(status || null);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalStatus(null);
  };

  const columns: TableColumn<TransactionData>[] = [
    {
      name: "Reference",
      selector: (row) => row.reference,
      sortable: true,
      width: "220px",
    },
    {
      name: "Recipient",
      selector: (row) => row.msisdn ?? "N/A",
    },
    {
      name: "Network",
      selector: (row) => row.network ?? "N/A",
    },
    {
      name: "Amount",
      selector: (row) => row.amount ?? "N/A",
    },
    {
      name: "Date",
      selector: (row) => formatDate(row.created_at),
      width: "250px",
    },
    {
      name: "Status",
      cell: (row) => (
        <button
          onClick={() =>
            openModal(
              row.status === "completed" ? "Completed" : row.data?.message
            )
          }
          className={`py-1 px-3 rounded ${getStatusStyle(row.status)}`}
        >
          Click
        </button>
      ),
    },
  ];

  const closeDetailsModal = () => {
    setIsDetailsModalOpen(false);
    setSelectedReference("");
  };

  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <SearchBar onSearch={onSearch} />
        <CSVLink
          data={prepareCSVData(data)}
          filename={`transactions_${new Date().toISOString()}.csv`}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
        >
          Export CSV
        </CSVLink>
      </div>
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={paginationData.total}
        onChangePage={handlePageChange}
        paginationPerPage={paginationData.per_page}
        paginationDefaultPage={paginationData.current_page}
        striped
        highlightOnHover
        customStyles={{
          headCells: {
            style: {
              fontWeight: "bold",
              backgroundColor: "#f8f8f8",
            },
          },
          cells: {
            style: {
              paddingLeft: "8px",
              paddingRight: "8px",
            },
          },
        }}
      />
      <StatusModal
        isOpen={isModalOpen}
        onClose={closeModal}
        status={modalStatus}
      />

      <DetailsModal
        isOpen={isDetailsModalOpen}
        onClose={closeDetailsModal}
        reference={selectedReference}
      />
    </>
  );
};

export default TransactionTable;
