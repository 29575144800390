import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import WalletBalanceCard from "./WallanceBalanceCard";
import BaseURL from "../../utils/url/http";

interface WalletBalanceResponse {
  status: number;
  message: string;
  data: {
    userBalance: string;
  };
}

const WalletBalanceContainer: React.FC = () => {
  const [showBalance, setShowBalance] = useState(() => {
    const saved = localStorage.getItem("showBalance");
    return saved !== null ? JSON.parse(saved) : true;
  });

  const fetchWalletBalance = async (): Promise<WalletBalanceResponse> => {
    const response = await BaseURL.get("vasadmin/walletBalance");
    return response.data;
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["walletBalance"],
    queryFn: fetchWalletBalance,
  });

  useEffect(() => {
    localStorage.setItem("showBalance", JSON.stringify(showBalance));
  }, [showBalance]);

  const toggleBalanceVisibility = () => {
    setShowBalance((prev: boolean) => !prev);
  };

  if (isLoading)
    return (
      <div className="flex justify-center items-center">
        <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-green-500 border-solid"></div>
      </div>
    );
  if (error) return <p>Error loading balance</p>;

  return (
    <WalletBalanceCard
      balance={parseFloat(data?.data.userBalance ?? "0")}
      showBalance={showBalance}
      toggleBalanceVisibility={toggleBalanceVisibility}
    />
  );
};

export default WalletBalanceContainer;
