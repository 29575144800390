import React, { useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import StatusModal from "./StatusModal";
import DetailsModal from "./DetailsModal";
import SearchBar from "./SearchBar";

interface TransactionData {
  id: number;
  user_id: number;
  reference: string;
  amount?: string | null;
  msisdn?: string | null;
  network?: string | null;
  status: string;
  vendor: string;
  created_at: string;
  updated_at: string;
  data?: {
    message?: string;
    receipt?: string;
    amount?: string | null;
    recipient?: string | null;
    network?: string | null;
    date?: string;
    utilityBundle?: {
      utility?: string;
    };
    reference?: string;
  };
}

interface TransactionTableProps {
  data: TransactionData[];
  loading: boolean;
  onSearch: (reference: string) => void;
  onFilter: (
    startDate: string | null,
    endDate: string | null,
    status: string | null
  ) => void;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  itemsPerPage: number;
}
const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

const getStatusStyle = (status: string) => {
  return status === "completed" ? "text-green-500" : "text-red-500";
};

const TransactionTable: React.FC<TransactionTableProps> = ({
  data,
  loading,
  onSearch,
  onFilter,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [modalStatus, setModalStatus] = useState<string | null>(null);
  const [selectedReference, setSelectedReference] = useState<string>("");
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [status, setStatus] = useState<string | null>(null);

  const openModal = (status?: string) => {
    setModalStatus(status || null);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalStatus(null);
  };

  const handleFilter = () => {
    onFilter(startDate, endDate, status);
  };

  const columns: TableColumn<TransactionData>[] = [
    {
      name: "Reference",
      selector: (row) => row.reference,
      sortable: true,
      width: "200px",
    },
    {
      name: "Recipient",
      selector: (row) => row.msisdn ?? "N/A",
    },
    {
      name: "Vendor",
      selector: (row) => row.vendor ?? "N/A",
    },
    {
      name: "Network",
      selector: (row) => row.network ?? "N/A",
    },
    {
      name: "Amount",
      selector: (row) => row.amount ?? "N/A",
    },
    {
      name: "Date",
      selector: (row) => formatDate(row.created_at),
      width: "250px",
    },
    {
      name: "Status",
      cell: (row) => (
        <button
          onClick={() =>
            openModal(
              row.status === "completed" ? "Completed" : row.data?.message
            )
          }
          className={`py-1 px-3 rounded ${getStatusStyle(row.status)}`}
        >
          Click
        </button>
      ),
    },
    {
      name: "View",
      cell: (row) => (
        <button
          onClick={() => openDetailsModal(row.data?.reference ?? "")}
          className="py-2 px-6 rounded bg-red-500 text-white hover:bg-red-600 transition-colors"
        >
          See Full Details
        </button>
      ),
    },
  ];

  const openDetailsModal = (reference: string) => {
    setSelectedReference(reference);
    setIsDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setIsDetailsModalOpen(false);
    setSelectedReference("");
  };

  return (
    <>
      <div className="mb-4 flex justify-between items-center">
        <SearchBar onSearch={onSearch} />
        <div className="flex items-center space-x-4">
          <input
            type="date"
            className="border rounded px-2 py-1"
            value={startDate || ""}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <input
            type="date"
            className="border rounded px-2 py-1"
            value={endDate || ""}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <select
            className="border rounded px-2 py-1"
            value={status || ""}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">All</option>
            <option value="completed">Completed</option>
            <option value="pending">Pending</option>
            <option value="failed">Failed</option>
            <option value="error">Error</option>
          </select>
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            onClick={handleFilter}
          >
            Filter
          </button>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer // Enable server-side pagination
        paginationTotalRows={totalPages * itemsPerPage} // Total rows based on pages and items per page
        onChangePage={onPageChange} // Handle page change event
        striped
        highlightOnHover
        customStyles={{
          headCells: {
            style: {
              fontWeight: "bold",
              backgroundColor: "#f8f8f8",
            },
          },
          cells: {
            style: {
              paddingLeft: "8px",
              paddingRight: "8px",
            },
          },
        }}
      />

      <StatusModal
        isOpen={isModalOpen}
        onClose={closeModal}
        status={modalStatus}
      />

      <DetailsModal
        isOpen={isDetailsModalOpen}
        onClose={closeDetailsModal}
        reference={selectedReference}
      />
    </>
  );
};

export default TransactionTable;
