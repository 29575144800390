import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import Logo from "../../assets/VAS2Nets-Logo.png";

const Header: React.FC = () => {

  const variants = {
    hidden: { opacity: 0, x: -200 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  return (
    <AnimatePresence>
      <motion.header
        className="flex items-center bg-white shadow-sm justify-between px-4 py-3"
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
        variants={variants}
      >
        <img
          src={Logo}
          alt="vas2nets logo"
          className="max-w-sm h-[50px] w-[150px] xl:max-w-md md:h-[80px] md:w-[240px]"
        />
        <p>
          <span className="text-red-500 font-bold uppercase text-md md:text-xl">
            vas<span className="lowercase">vending</span>
          </span>{" "}
          <span className="capitalize"> portal</span>
        </p>
      </motion.header>
    </AnimatePresence>
  );
};

export default Header;
