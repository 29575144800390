import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { User, Wallet, Percent, CoinsIcon } from "lucide-react";
import BaseURL from "../../../../utils/url/http";

interface VendorData {
  id: number;
  name: string;
  username: string | null;
  role: string;
  wallet_balance: number;
  commission_rate: number;
  commission: number;
}

const Info: React.FC = () => {
  const { name } = useParams();
  const [vendorInfo, setVendorInfo] = useState<VendorData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVendorInfo = async () => {
      try {
        const res = await BaseURL.get(`vasadmin/vendors/${name}`);
        if (res.data.status === "success") {
          setVendorInfo(res.data.data);
        }
      } catch (error) {
        console.error("Error fetching vendor info:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchVendorInfo();
  }, [name]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-lg">Loading...</div>
      </div>
    );
  }

  if (!vendorInfo) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-lg text-red-500">
          Failed to load vendor information
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 md:p-6 lg:p-8  mx-auto">
      <div className="flex items-center gap-3 mb-8">
        <User className="w-8 h-8 text-blue-600" />
        <h1 className="text-2xl md:text-3xl font-bold text-gray-800 capitalize">
          {vendorInfo.name}
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-xl p-6 text-white shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold">Wallet Balance</h2>
            <Wallet className="w-6 h-6" />
          </div>
          <p className="text-3xl font-bold">
            ₦{vendorInfo.wallet_balance.toLocaleString()}
          </p>
        </div>

        <div className="bg-gradient-to-br from-purple-500 to-purple-600 rounded-xl p-6 text-white shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold">Commission Rate</h2>
            <Percent className="w-6 h-6" />
          </div>
          <p className="text-3xl font-bold">{vendorInfo.commission_rate}%</p>
        </div>

        <div className="bg-gradient-to-br from-emerald-500 to-emerald-600 rounded-xl p-6 text-white shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold">Commission</h2>
            <CoinsIcon className="w-6 h-6" />
          </div>
          <p className="text-3xl font-bold">
            ₦{vendorInfo.commission.toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Info;
