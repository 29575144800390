import React, { useState, useEffect } from "react";
import TotalTransactions from "./components/vendor/total_transactions/TotalTransactions";
import TotalCommission from "./components/vendor/total_commission/TotalCommission";
import TotalVendors from "./components/vendor/total_vendors/TotalVendors";
import TotalAmount from "./components/vendor/total_amount/TotalAmount";
import BaseURL from "./components/utils/url/http";
import Success from "./components/vendor/status/Success";
import Unsuccess from "./components/vendor/status/Unsuccess";
import History from "./components/vendor/history/History";

const Vendors: React.FC = () => {
  const [totalCommission, setTotalCommission] = useState<number | null>(null);
  const [totalVendors, setTotalVendors] = useState<number | null>(null);
  const [totalAmount, setTotalAmount] = useState<number | null>(null);
  const [totalTran, setTotalTran] = useState<number | null>(null);
  const [succTran, setSuccTran] = useState<number | null>(null);
  const [unsuccTran, setUnsuccTran] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchVendorInfo = async () => {
    try {
      const [vendorsResponse, transactionsResponse] = await Promise.all([
        BaseURL("vasadmin/vendors"),
        BaseURL("vasadmin/transactions"),
      ]);

      setTotalCommission(vendorsResponse.data.total_commission);
      setTotalVendors(vendorsResponse.data.total_vendors);
      setTotalTran(transactionsResponse.data.total);
      setTotalAmount(transactionsResponse.data.totalAmount);
      setSuccTran(transactionsResponse.data.successful);
      setUnsuccTran(transactionsResponse.data.unsuccessful);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching vendor data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVendorInfo();
  }, []);

  return (
    <section className="container mx-auto px-4 py-24">
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
          <TotalTransactions totalTran={totalTran} />
          <TotalCommission commission={totalCommission} />
          <TotalVendors vendors={totalVendors} />
          <TotalAmount totalAmount={totalAmount} />
          {/* <Success succTran={succTran} /> */}
          {/* <Unsuccess unsuccTran={unsuccTran} /> */}
        </div>
      )}

      <section>
        <History />
      </section>
    </section>
  );
};

export default Vendors;
