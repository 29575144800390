import React from "react";
import { motion } from "framer-motion";

interface StatusModalProps {
  isOpen: boolean;
  onClose: () => void;
  status: string | null;
}

const StatusModal: React.FC<StatusModalProps> = ({
  isOpen,
  onClose,
  status,
}) => {
  const causes = [
    "Network timeout",
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
        <h2 className="text-lg font-bold mb-4">Status Information</h2>
        <p className="mb-4">{status || "Empty status"}</p>
        {!status && (
          <div>
            <h3 className="font-semibold">Possible Causes:</h3>
            <ul className="list-disc pl-5">
              {causes.map((cause, index) => (
                <li key={index}>{cause}</li>
              ))}
            </ul>
          </div>
        )}
        <button
          onClick={onClose}
          className="mt-4 bg-red-500 text-white py-2 px-4 rounded"
        >
          Close
        </button>
      </div>
    </motion.div>
  );
};

export default StatusModal;
